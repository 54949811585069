<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn v-if="hasRight('DEVICE_WRITE')" v-bind="$config.buttonAttrs.floating" :to="{ name: 'NewController' }"
        title="Hozzáadás">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Kontrollerek</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field v-model="search.name" @input="searchEventHandler" label="Megnevezés" clearable
                hide-details />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-select label="Épület" :items="buildings" item-text="name" item-value="id" @change="searchEventHandler"
                v-model="search.building_id" clearable hide-details />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete label="Épület szint" :items="buildingLevels" item-text="name" item-value="id"
                @change="searchEventHandler" v-model="search.building_level_id" clearable hide-details
                :placeholder="search.building_id ? 'Összes szint' : 'Épület kötelező'"
                :readonly="!search.building_id" />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete label="Kontroller típus" :items="controllerTypes" item-text="name" item-value="id"
                @change="searchEventHandler" v-model="search.controller_type_id" clearable hide-details />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table :loading="loading" :headers="dataTable.headers" :items="dataTable.items"
        :server-items-length="dataTable.itemsLength" :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps" calculate-widths>
        <template #[`item.controller_name`]="{ item }">
          <v-tooltip v-if="progresses[item.serial_number]" bottom color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ item.controller_name }}</span>
            </template>
            <span>{{ progresses[item.serial_number].process }}: {{ progresses[item.serial_number].progress }}</span>
          </v-tooltip>
          <span v-else>{{ item.controller_name }}</span>
        </template>

        <template #[`item.serial_number`]="{ item }">
          <v-tooltip v-if="item.serial_number.length > 16" bottom color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ item.serial_number.substring(0, 8) }}...{{ item.serial_number.substring(item.serial_number.length -
                6) }}
              </span>
            </template>
            <span>{{ item.serial_number }}</span>
          </v-tooltip>
          <span v-else>{{ item.serial_number }}</span>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-btn v-if="hasRight('DEVICE_WRITE')" small text fab dark depressed color="secondary" class="rounded-sm"
            :to="{ name: 'ControllerEditor', params: { id: item.element_id } }" title="Szerkesztés">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn v-if="hasRight('DEVICE_WRITE')" text fab small color="secondary" class="rounded-sm m-3"
            @click="deleteConnection(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-btn v-if="hasRight('DEVICE_WRITE')" text fab small color="secondary" class="rounded-sm m-3" @click="
            uploadCards(item)
            " title="Kártyák feltöltése">
            <v-icon>mdi-package-up</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'ControllerList',
      fetchItems: this.fetchControllers,
      dataTable: {
        options: {
          sortBy: ['controller_name'],
        },
        headers: [
          { text: 'Megnevezés', value: 'controller_name' },
          { text: 'Alias', value: 'alias' },
          { text: 'Épület', value: 'building_name' },
          { text: 'Épület szint', value: 'building_level_name', width: 120 },
          { text: 'Sorozatszám', value: 'serial_number', width: 160 },
          { text: 'Típus', value: 'controller_type', width: 160 },
          { text: 'IP', value: 'ip' },
          { text: 'Port', value: 'port' },
          { text: '', value: 'actions', sortable: false, align: 'end', width: 140 },
        ],
      },
      buildings: [],
      buildingLevels: [],
      controllerTypes: [],
      search: {
        name: '',
        building_id: 0,
        building_level_id: 0,
        controller_type_id: 0,
      },
      fetchingProgress: true,
      progresses: {},
    };
  },

  watch: {
    async 'search.building_id'() {
      try {
        const response = await this.$http.post(`building-levels/list/`, {
          building_id: this.search.building_id,
        });
        this.buildingLevels = response.building_levels;
        this.search.building_level_id = 0;
      } catch (e) {
        console.error(e);
      }
    },
  },

  methods: {
    parseSearchQueries() {
      return {
        name: this.$route.query.name,
        building_id: this.$route.query.building_id,
        building_level_id: this.$route.query.building_level_id,
        controller_type_id: this.$route.query.controller_type_id,
      };
    },

    async uploadCards(item) {
      const confirm = await this.$dialog.confirm({
        type: 'warning',
        text: `Ez a parancs feltölti az összes kártyát a következő kontrollerre: <b>${item.name}</b>.<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) return;

      const response = await this.$http.post('controllers/control', {
        element_id: item.element_id,
        type: 'KARTYA_FELTOLTES_KONTROLLERRE',
      });

      if (response.status === 'OK') {
        this.$dialog.notify.info('Kártyafeltöltés parancs kiadva.');
      }
    },

    async controllerOperation(id, operation_type, text) {
      const confirm = await this.$dialog.confirm({
        type: 'warning',
        text: text,
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        const response = await this.$http.post('operations/add_in', {
          operation_type: operation_type,
          params: {
            id: id,
          },
        });
        if (response.status === 'OK') {
          this.$dialog.notify.info('A művelet kiadva.');
        }
      } catch (e) {
        console.error(e);
      }
    },

    async fetchBuildings() {
      try {
        const response = await this.$http.get('buildings/list');
        this.buildings = response.buildings;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchControllerTypes() {
      try {
        const response = await this.$http.get('controller-types/list');
        this.controllerTypes = response.controller_types;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchControllers() {
      this.loading = true;
      try {
        const response = await this.$http.post('controllers/list', this.options);
        this.dataTable.items = response.controllers;
        this.dataTable.itemsLength = response.controllers_count;
        this.dataTable.options.page = this.$route.query.page * 1 || 1;

        this.fetchControllerTypes();
        this.fetchBuildings();
        this.fetchProgresses();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async fetchProgresses() {
      try {
        const response = await this.$http.get('controllers/progress');
        for (const progress of response.progresses || []) {
          // Vue.set(this.progresses, progress.serial_number, { process: progress.process, progress: progress.progress });
          this.progresses[progress.serial_number] = { process: progress.process, progress: progress.progress };
        }

        this.$forceUpdate();

        if (this.fetchingProgress) {
          setTimeout(() => {
            this.fetchProgresses();
          }, 3000);
        }
      }
      catch (e) {
        console.error(e);
      }
    },

    async deleteConnection(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<b>${item.name}</b> kontroller törlésére készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        const response = await this.$http.get(`controllers/delete/${item.element_id}`);
        this.fetchControllers();
        if (response.status === 'OK') {
          this.$dialog.notify.info('A kontroller törölve');
        }
      } catch (e) {
        console.error(e);
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        name: this.search.name || '',
        building_id: this.search.building_id || 0,
        building_level_id: this.search.building_level_id || 0,
        controller_type_id: this.search.controller_type_id || 0,
      };
    },
  },

  onBeforeUnmount() {
    this.fetchingProgresses = false;
  },
};
</script>
